import React, { useContext, useEffect, useRef, useState } from 'react'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from "react-notifications";
import './RequisitionDetails.css';
import { Autocomplete } from '@material-ui/lab';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, OutlinedInput, TextField, TextareaAutosize, makeStyles } from '@material-ui/core';
import { CloudUpload, PersonAdd, Search } from '@material-ui/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RecentActivities from '../../components/RecentActivities/RecentActivities.js';
import GetAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus"
import getRequisitionDetailsbyId from '../../apis/getRequisitionDetailsbyId/getRequisitionDetailsbyId';
import getAllDivionByOrg from '../../apis/getAllDivionByOrg/getAllDivisionByOrg';
import recruiterLeadernameByDivision from '../../apis/recruiterLeadernameByDivision/recruiterLeadernameByDivision';
import updateRequisitionById from '../../apis/updateRequisitionById/updateRequisitionById';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import configJSON from "../../Config.json";
import countallstatus from "../../apis/countallstatusforrequitionforcandidates/countallstatusforrequitionforcandidates";
import {
    getDivisionByOrg,
}
    from "../../apis/organisationAdminAPI/manageDivision";
import "../ATS_Dashboard/RecLead.css";
import getLocations from '../../apis/getLocations/getLocations';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { UserTypeContext } from '../../AppRoute';
import TeamLeaderRequistionAssignment from '../../components/RequisitionAssignment/TeamLeaderRequistionAssignment';
import RecruiterRequisitionAssignment from '../../components/RequisitionAssignment/RecruiterRequisitionAssignment';
import UploadResumeModal from '../../components/UploadResumeModal/UploadResumeModal';
import checkRequisitionExists from '../../apis/checkRequisitionExists/checkRequisitionExists';
import spocByClientid from '../../apis/spocByClientid';


const useStyles = makeStyles((theme) => ({
    customAutocomplete: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '8px', // Set the padding to 8px
        },
    },

    customDropdown: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '6.5px',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '&.MuiInput-underline:before': {
            border: 'none',
        },
        '&.MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent', // Set the background color to transparent when focused
        },

    },

    customTextField: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '3.5px',
        fontFamily: 'Segoe UI',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '&.MuiInput-underline:before': {
            border: 'none',
        },
        '&.MuiInput-underline:after': {
            border: 'none',
        },
        '&.MuiSelect-select:focus': {
            backgroundColor: 'transparent', // Set the background color to transparent when focused
        },



    },
    customNumberField: {
        '& .MuiOutlinedInput-root': {

            '& .MuiOutlinedInput-input': {
                padding: '13px',
            },
            //remove onactive border
            '&.MuiOutlinedInput-notchedOutline': {
                border: '1px solid lightgrey',
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid lightgrey',
            },
        },
    },
    customTextArea: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '8px',
        fontSize: '16px',
        fontFamily: 'Segoe UI',
        maxHeight: '100%',
        maxWidth: '100%',
        marginBottom: '10px',
        '&:focus': {
            outline: 'none',
            border: '1px solid lightgrey',
        },
    },
    customReqDetailsBtn: {
        backgroundColor: '#009EFD',
        color: 'white',
        padding: "7px 40px",
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: '#2D62ED',
            color: 'white',
        },
        '&:focus': {
            backgroundColor: '#2D62ED',
            color: 'white',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    AssignRecruiterBtn: {
        backgroundColor: '#2ECA75',
        color: 'white',
        marginBottom: '10px',
        width: '100%',
        padding: '10px',
        fontFamily: 'Segoe UI',
        display: 'flex', // Added display flex
        flexDirection: 'row',
        '& .MuiButton-label': {
            display: 'flex', // Added display flex
            flexDirection: 'row',
            justifyContent: 'center'
        },
        '&:hover': {
            //make background color a little darker
            backgroundColor: '#32c173',
            color: 'white',
        },

    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: '"Segoe UI Semibold", sans-serif !important',
        fontWeight: 'bold',
        fontSize: "10px",
        color: "#ffffff !important",
        textShadow: "0px 1px 2px black !important"
    },

}
));
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1A8FFF",
    },
    tooltip: {
        backgroundColor: "#1A8FFF",
        padding: "4px 13px",
        fontFamily: "Segoe-Semibold",
        fontSize: "13px",
    },
    buttonAlign: {
        display: "flex",
        alignItems: "center",
        padding: "24px 42px 24px 42px !important",
    },
}));


function RequisitionDetailsBody(props) {


    const history = useHistory();
    const { userType, userRecords } = useContext(UserTypeContext);
    const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : userRecords?.recruitmentpartnerId;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : userRecords?.role?.roleName;
    let requisitionId = props?.location?.requisitionId

    const [requisitionResponseData, setRequisitionResponseData] = useState([])
    const [isClientChanged, setisClientChanged] = useState(false)
    const [status, setStatus] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [divisionByOrgData, setDivisionByOrgData] = useState([])
    const classes = useStyles();
    const [placementTypeOption, setPlacementTypeOption] = useState([
        { PositionType: "Direct Placement" }, { PositionType: "Contract" }, { PositionType: "Right To Hire" }, { PositionType: "Full Time" }, { PositionType: "Contract" }
    ]);
    const [clientNameOption, setClientNameOption] = useState([])
    const [divisionName, setDivisionName] = useState([]);
    const [pocName, setpocName] = useState([]);
    const [divisionstate, setDivisionstate] = useState(false);
    const [priority, setPriority] = useState([
        { name: "High" }, { name: "Low" }
    ]);
    const [allRecruiterLeader, setallRecruiterLeader] = useState([]);
    const [currencyBillRate, setCurrencyBillRate] = useState([
        { value: "INR/HOUR" }, { value: "INR/DAY" }, { value: "INR/YEAR" }
    ]);
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [cloneRequisitionModal, setCloneRequisitionModal] = useState(false)
    const [locationSearchText, setLocationSearchText] = useState("")
    const [locationLoading, setLocationLoading] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [isReferenceIdCopied, setisReferenceIdCopied] = useState(false);
    const [atvBtn, setAtvBtn] = useState(false);
    const [recruiterModalIsOpen, setRecruiterModalIsOpen] = useState(false);
    // const [selectedTeamLeader, setSelectedTeamLeader] = useState({});
    const [selectedTeam, setSelectedTeam] = useState({});
    const teamLeaderRequisitionRef = useRef(); // reference for Child component
    const recruiterRequisitionRef = useRef(); // reference for Child component
    const [oldData, setOldData] = useState({})


    const [requisitionDetailsData, setrequisitionDetailsData] = useState(
        {
            reqreferenceId: "",
            primarySkill: "",
            placementType: "",
            clientJobId: "",
            clientName: "",
            endClientName: "",
            division: "",
            priority: "",
            location: [],
            minimumBillRate: "",
            maxiMumBillRate: "",
            currency: "",
            fee: "",
            startDate: "",
            endDate: "",
            openings: "",
            minimumExperienceLevel: "",
            maximumExperienceLevel: "",
            status: "",
            filledBy: "",
            assignedTo: "",
            jobDescription: "",
            documentLink: "",
            poc: ""
        }
    )
    const [activCount, setactivCount] = useState({});
    const [curStatus, setCurStatus] = useState()
    const changeCopyText = () => {
        setisReferenceIdCopied(false);
    };
    const copyReferenceId = (e, referenceId) => {
        setisReferenceIdCopied(true);
        navigator.clipboard.writeText(referenceId);
        setTimeout(changeCopyText, 3000);
    };

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    const getWrappedReferenceId = (refId) => {
        return (
            <BootstrapTooltip title={isReferenceIdCopied ? "Copied" : "Click to copy Reference Id"}>
                <div
                    className='reqDetails-referenceId'
                    title={isReferenceIdCopied ? "Copied" : "Click to copy Email"}
                    onClick={(e) => copyReferenceId(e, refId)}
                >
                    {refId}
                    <FileCopyIcon style={{ marginRight: '5px', color: '#31B3F8' }} />

                </div>
            </BootstrapTooltip>
        );
    }


    const goToDashBoard = () => {
        if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
            history.push({
                pathname: "/requisition-dashboard",
                state: { id: idContextTest, recruitmentPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
            });
        } else {
            history.push({
                pathname: "/dashboard",
                state: {
                    userType: userType,
                    recruitmentPartnerId: userRecruitmentpartnerId,
                    id: idContextTest,
                    userRecords: userRecords,
                    roleName: userRole
                }
            });
        }
    };

    const goToSearchCandidate = () => {
        history.push({
            pathname: `/candidate-search-talent-community/${requisitionId}`,
            state: { id: idContextTest, recruitementPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
        });
    }

    const openModal = async () => {
        teamLeaderRequisitionRef.current.setTemLeaderRequisitionsData(); //Trigerring the child component function
        setmodalIsOpen(true);
    }
    const closeModal = () => {
        teamLeaderRequisitionRef.current.clearTeamLeaderRequistionData(); //Trigerring the child component function
        setmodalIsOpen(false);
    }

    const handleUploadResumes = () => {
        setShowResumeModal(true);
    }



    const cloneConfirmRequisitionHandler = () => {
        setCloneRequisitionModal(true);
    }

    const closeCloneRequisitionModal = () => {
        setCloneRequisitionModal(false);
    }


    const getRequisitionDetail = async (step) => {
        try {
            setIsDataLoading(true)
            let requisitionResponse = await getRequisitionDetailsbyId(requisitionId, { userType: userType === 'Admin' ? userRole : userType, id: idContextTest, recruitmentpartnerId: userRecruitmentpartnerId, userRecruitmentpartnerId: userRecruitmentpartnerId, userId: idContextTest })
            requisitionResponse = requisitionResponse.data.data[0]
            setRequisitionResponseData(requisitionResponse)

            let ClientResponse
            let requisitionStatusResponse
            let divisionResponse
            let pocResponse

            if (step === 'initial') {

                ClientResponse = await getClientByOrg({ recruitmentPartnerId: userRecruitmentpartnerId });
                setClientNameOption(ClientResponse.data.data)
                ClientResponse = ClientResponse.data.data

                requisitionStatusResponse = await GetAllRequisitionStatus();
                setStatus(requisitionStatusResponse.data.data)
                requisitionStatusResponse = requisitionStatusResponse.data.data

                let payload = {
                    isManageClient: false
                }
                divisionResponse = await getAllDivionByOrg(userRecruitmentpartnerId, payload);
                divisionResponse = divisionResponse.data.data
                recruiterLeaderByDivision(requisitionResponse?.divisionId)
            }
            else {
                ClientResponse = clientNameOption
                requisitionStatusResponse = status
                divisionResponse = divisionByOrgData
            }

            let isDisabled = true;
            let clientNameData = (ClientResponse.filter((item) => item._id === requisitionResponse.clientDetails._id))[0];
            let pocNameData

            if (userType === 'MIS Operator') {
                let requisitionStatus_id = requisitionResponse.recruiterLeaderRequisitionDetails.status.subCodeId
                let OpenStatus = (requisitionStatusResponse.filter((item) => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN))[0].subList
                let unassignedStatus = OpenStatus.filter((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED)[0]
                if (unassignedStatus._id === requisitionStatus_id) {
                    isDisabled = false
                }
                pocResponse = await spocByClientid(clientNameData._id, userRecruitmentpartnerId);
                pocNameData = (pocResponse?.data?.data.filter((item) => item._id === requisitionResponse.spocId))[0];
                const arr = pocResponse.data.data.flatMap(item => item);
                setpocName(arr || [])
            }
            let divisionNameData = (divisionResponse.filter((item) => item._id === requisitionResponse.divisionId))[0];
            setrequisitionDetailsData({
                ...requisitionDetailsData,
                poc: pocNameData || requisitionResponse?.spocDetails[0] ||'',
                requisitionDetailId: requisitionResponse._id,
                primarySkill: requisitionResponse.primarySkill,
                reqreferenceId: requisitionResponse.reqreferenceId,
                placementType: (placementTypeOption.filter((item) => item.PositionType === requisitionResponse.placementType))[0],
                clientJobId: requisitionResponse.clientJobId,
                clientName: clientNameData,
                endClientName: requisitionResponse.endClientName,
                priority: priority.filter((item) => item.name === requisitionResponse.priority)[0],
                minimumBillRate: requisitionResponse.billRate.minimum,
                maxiMumBillRate: requisitionResponse.billRate.maximum,
                currency: currencyBillRate.filter((item) => item.value === requisitionResponse.currency)[0],
                fee: requisitionResponse.fee,
                location: requisitionResponse.location,
                startDate: new Date(requisitionResponse.startDate) || "",
                endDate: new Date(requisitionResponse.endDate) || "",
                openings: requisitionResponse.openings,
                jobDescription: requisitionResponse.jobDescription,
                minimumExperienceLevel: requisitionResponse?.minExperience,
                maximumExperienceLevel: requisitionResponse?.maxExperience,
                status: (requisitionStatusResponse.filter((item) => item._id === requisitionResponse.status.statusCodeId))[0],
                filledBy: requisitionResponse.filledBy,
                division: divisionNameData,
                assignedTo: requisitionResponse.recruiterLeaderDetails,
                isDisabled: isDisabled,
                documentLink: requisitionResponse.uploadDocuments,
                oldAssignedTo: requisitionResponse.recruiterLeaderDetails,
            });
            setOldData({ openings: requisitionResponse.openings, status: (requisitionStatusResponse.filter((item) => item._id === requisitionResponse.status.statusCodeId))[0] })
            setIsDataLoading(false)

            const divi1 = clientNameData.assignedDivisions;
            const arr1 = divi1.flatMap(item => item);
            setDivisionName(arr1);
        } catch (error) {
            console.log(error)
        }

    }

    const allDivisonData = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId,
            isActive: true
        }
        try {
            let response = await getDivisionByOrg(payload);
            setDivisionByOrgData(response.data.data)
        } catch (error) {
            console.log(error);
        }
    };
    const getCountOfActivity = async () => {
        let user = userType === 'Admin' ? userRole : userType, id = idContextTest

        if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
            user = userType === 'Admin' ? userRole : userType
            id = userRecruitmentpartnerId
        }

        let fields = {
            "MIS Operator": "MISId",
            "Recruitment Leader": "RecruitmentLeaderId",
            "Team Leader": "teamLeader",
            "Recruiter": "recruiterId",
            "Organization Admin": "orgId"
        }

        let payload_data = {
            "requisitionId": requisitionId,
            recruitmentpartnerId: userRecruitmentpartnerId,
            [fields[user]]: id,
        }

        let allCount = await countallstatus(payload_data)
        //   setactivCount(allCount?.data?.statusCounts || {})
    }
    useEffect(() => {
        if (userRecruitmentpartnerId) {
            getRequisitionDetail('initial')
            allDivisonData()
            allRequisitionStatus();
            getCountOfActivity();
            if (userType === 'MIS Operator') {
                setrequisitionDetailsData([]);
            }
        }
    }, [requisitionId])

    //----------- Field Handlers ------------

    const primarySkillHandler = (e) => {
        const value = e.target.value;
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, primarySkill: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, primarySkill: "" });
        }
    }
    const placementTypeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, placementType: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, placementType: "" });
        }
    }

    const clientJobIdHandler = (e) => {
        const value = e.target.value;
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, clientJobId: e.target.value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, clientJobId: "" });
        }
    }

    const clientNameChangeHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setrequisitionDetailsData((prev) => ({
                ...prev,
                clientName: value,
                division: "",
                assignedTo: "",
                poc: ""
            }));
            setDivisionstate(true);
            getpoc(value._id, value.recruitmentpartnerId)
            const divisions = value.assignedDivisions;
            let activeDivisions = divisions.flatMap(item => item);
            activeDivisions = activeDivisions.filter(item => item.isActive === true)
            setDivisionName(activeDivisions);
            setisClientChanged(true);
        } else {
            setrequisitionDetailsData((prev) => ({
                ...prev,
                clientName: value,
                division: "",
                assignedTo: "",
                poc: ""
            }));

        }
    }
    const getpoc = async (clientId, recruitmentpartnerId) => {
        try {
            let response = await spocByClientid(clientId, recruitmentpartnerId);
            const arr = response.data.data.flatMap(item => item);
            setpocName(arr || [])
        } catch (error) {
            console.log(error)
        }
    }

    const endClientNameHandler = (e) => {
        const value = e.target.value;
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, endClientName: e.target.value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, endClientName: "" });
        }
    }
    const pocChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, poc: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, poc: "" });
        }
    }

    const divisionChangeHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, division: value, assignedTo: "" });
            recruiterLeaderByDivision(value._id)
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, division: "", assignedTo: "" });
        }
    }

    const recruiterLeaderByDivision = async (id) => {
        try {
            let recruiterLeaderData = await recruiterLeadernameByDivision(id, userRecruitmentpartnerId);
            recruiterLeaderData = recruiterLeaderData.data?.data;
            setallRecruiterLeader(recruiterLeaderData ? recruiterLeaderData.map(item => item.recruiterLeader) : []);

        } catch (error) {
            console.log(error);
        }
    }

    const getFilteredRecruiterLeaderOptions = () => {
        let filteredOptions = allRecruiterLeader.filter(item => item?.id !== requisitionDetailsData?.assignedTo?.id)
        return filteredOptions
    }

    const priorityChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, priority: value });

        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, priority: "" });
        }
    }


    const currencyBillRateChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, currency: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, currency: "" });
        }
    }

    const feeChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, fee: event.target.value });
    }


    const startDateChangeHandler = (date) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, startDate: date });
    };

    const endDateChangeHandler = (date) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, endDate: date });
    }

    const openingChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, openings: event.target.value });
    }

    const minimumExperienceHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, minimumExperienceLevel: event.target.value });
    }

    const maximumExperienceHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, maximumExperienceLevel: event.target.value });
    }


    const allRequisitionStatus = async () => {
        try {
            let response = await GetAllRequisitionStatus();
            setStatus(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const statusChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, status: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, status: "" });
        }
    }

    const filledByChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, filledBy: event.target.value });

    }

    const assignToChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, assignedTo: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, assignedTo: "" });
        }

    }

    const jobDescriptionChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, jobDescription: event.target.value });
    }

    const minimumbillRateChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, minimumBillRate: event.target.value });
    }

    const MaximumbillRateChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, maxiMumBillRate: event.target.value });
    }

    const openResume = (resumeUrl) => {
        window.open(resumeUrl, "_blank");
    };

    const getLocationsBreakDown = (locations) => {
        let city = []
        let state = []
        let country = []
        locations.forEach((item) => {
            let itemArr = item.split(",")
            if (itemArr.length === 1) {
                country.push(itemArr[0].trim())
                state.push(null)
                city.push(null)
            }
            else if (itemArr.length === 2) {
                state.push(itemArr[0].trim())
                country.push(itemArr[1].trim())
                city.push(null)
            }
            else {
                city.push(itemArr[0].trim())
                state.push(itemArr[itemArr.length - 2].trim())
                country.push(itemArr[itemArr.length - 1].trim())
            }
        })
        let locationObj = {
            city: city,
            state: state,
            country: country
        }
        return locationObj;
    }



    const saveHandler = async () => {
        setAtvBtn(true)
            let sum = requisitionResponseData?.teamLeaderRequisitionDetails?.reduce((total, item) => total + item.no_of_positions, 0);     
        if (requisitionDetailsData.primarySkill === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Primary Skill",
                "Error",
                2500
            );
        }
        else if(requisitionDetailsData.openings < sum)
        {
                 setAtvBtn(false)
                 NotificationManager.error(
                     `Opening can't be less than ${sum}`,
                     "Error",
                     2500
                 );
        }
        else if (requisitionDetailsData.clientName === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Client Name",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.location.length === 0) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Please enter Location",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.openings === 0) {
            setAtvBtn(false)
            NotificationManager.error(
                "Openings should be greater than 0",
                "Error",
                2500
            );
        }
        else if (!requisitionDetailsData.openings) {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter a value in openings",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.assignedTo === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Assign To",
                "Error",
                2500
            );
        } else if (requisitionDetailsData.division === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Division",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.priority === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Priority",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.placementType === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Placement Type",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionDetailsData.maxiMumBillRate) < parseInt(requisitionDetailsData.minimumBillRate)) {
            setAtvBtn(false)
            NotificationManager.error(
                "Minimum Bill Rate cannot be more than Maximum Bill Rate.",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.maximumExperienceLevel === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Maximum Experience",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.minimumExperienceLevel === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Minimum Experience",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionDetailsData.maximumExperienceLevel) < parseInt(requisitionDetailsData.minimumExperienceLevel)) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Minimum Experience cannot be more than Maximum Experience.",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.maximumExperienceLevel < 1) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Maximum years of Experience cannot be less than 1 year"
            )
        }else if(requisitionDetailsData.status === ""){
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Status",
                "Error",
                2500
            );
        }
        else {
            let isRecruiterLeaderChanged = false;
            if (requisitionDetailsData.assignedTo.id !== requisitionDetailsData.oldAssignedTo.id) {
                isRecruiterLeaderChanged = true
            }
            let locationObj = getLocationsBreakDown(requisitionDetailsData.location);
            let payload = {
                requisitionDetailId: requisitionDetailsData.requisitionDetailId,
                primarySkill: requisitionDetailsData.primarySkill,
                placementType: requisitionDetailsData.placementType.PositionType,
                clientJobId: requisitionDetailsData.clientJobId,
                clientId: requisitionDetailsData.clientName._id,
                endClientName: requisitionDetailsData.endClientName,
                divisionId: requisitionDetailsData.division._id,
                city: locationObj.city,
                state: locationObj.state,
                country: locationObj.country,
                location: requisitionDetailsData.location,
                minimumBillRate: requisitionDetailsData.minimumBillRate,
                maxiMumBillRate: requisitionDetailsData.maxiMumBillRate,
                currency: requisitionDetailsData.currency?.value,
                fee: requisitionDetailsData.fee,
                startDate: requisitionDetailsData.startDate,
                endDate: requisitionDetailsData.endDate,
                openings: requisitionDetailsData.openings,
                maxExperience: requisitionDetailsData?.maximumExperienceLevel,
                minExperience: requisitionDetailsData?.minimumExperienceLevel,
                priority: requisitionDetailsData.priority.name,
                filledBy: requisitionDetailsData?.status?.statusCode === configJSON?.requisitionStatusCodes?.FILLED ? requisitionDetailsData.filledBy : "",
                recruitmentLeaderId: requisitionDetailsData.assignedTo.id,
                recruitmentLeaderEmailId: requisitionDetailsData.assignedTo.email,
                jobDescription: requisitionDetailsData.jobDescription,
                misOperatorDetails: { id: userRecords.id, name: userRecords.displayName },
                oldAssignedTo: requisitionDetailsData.oldAssignedTo,
                isRecruiterLeaderChanged: isRecruiterLeaderChanged,
                isClientChanged: isClientChanged,
                reqreferenceId: requisitionDetailsData?.reqreferenceId,
                recruitmentpartnerId: userRecruitmentpartnerId,
                userRecruitmentpartnerId: userRecruitmentpartnerId,
                userId: idContextTest,
                spocId: requisitionDetailsData?.poc?._id || null,
                isChanged: { openings: parseInt(oldData.openings) != parseInt(requisitionDetailsData.openings) ? true : false },
                // userId: userRecords.id 
            }
            if (requisitionDetailsData.status.statusCode === configJSON?.requisitionStatusCodes?.OPEN) {
                let openStatus = status.find((item) => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN)
                let subStatus_unassigned = openStatus?.subList.find((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED)
                let subStatus_covered = openStatus?.subList.find((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.COVERED)
                let subStatus_uncovered = openStatus?.subList.find((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED)
                let payloadData = {
                    validation: "requisitionAssignedCandidates",
                    requisitionDetailId: requisitionResponseData?._id,
                    recruitmentpartnerId: userRecruitmentpartnerId
                }
                try {
                    let response = await checkRequisitionExists(payloadData);
                    //using requistionResposeData to get the old status before any changes
                    if (requisitionResponseData.status.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED) {
                        payload.status = {
                            statusCode: requisitionDetailsData.status.statusCode,
                            statusCodeId: Object(requisitionDetailsData.status._id),
                            subCode: subStatus_unassigned.subCode,
                            subCodeId: Object(subStatus_unassigned._id)
                        }
                    }
                    else if (requisitionResponseData.status.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.COVERED) {
                        if (requisitionDetailsData.openings > response?.data?.data[0]?.candidateRequisitions?.length) {
                            payload.status = {
                                statusCode: requisitionDetailsData.status.statusCode,
                                statusCodeId: Object(requisitionDetailsData.status._id),
                                subCode: subStatus_uncovered.subCode,
                                subCodeId: Object(subStatus_uncovered._id)
                            }
                        }
                        else {
                            payload.status = {
                                statusCode: requisitionDetailsData.status.statusCode,
                                statusCodeId: Object(requisitionDetailsData.status._id),
                                subCode: subStatus_covered.subCode,
                                subCodeId: Object(subStatus_covered._id)
                            }
                        }
                    }
                    else if (requisitionResponseData.status.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED) {
                        if (requisitionDetailsData.openings <= response?.data?.data[0]?.candidateRequisitions?.length) {
                            payload.status = {
                                statusCode: requisitionDetailsData.status.statusCode,
                                statusCodeId: Object(requisitionDetailsData.status._id),
                                subCode: subStatus_covered.subCode,
                                subCodeId: Object(subStatus_covered._id)
                            }
                        }
                        else {
                            payload.status = {
                                statusCode: requisitionDetailsData.status.statusCode,
                                statusCodeId: Object(requisitionDetailsData.status._id),
                                subCode: subStatus_uncovered.subCode,
                                subCodeId: Object(subStatus_uncovered._id)
                            }
                        }
                    }
                } catch (error) {
                    NotificationManager.error("Error fetching requistion assigned candidates", "Error", 3500);
                }
            } else {
                payload.status = {
                    statusCode: requisitionDetailsData.status.statusCode,
                    statusCodeId: Object(requisitionDetailsData.status._id),
                    subCode: requisitionResponseData.status.subCode,
                    subCodeId: Object(requisitionResponseData.status.subCodeId)
                }
            }
            try {
                payload.isChanged.status = oldData.status.statusCode != payload.status.statusCode ? true : false
                let updatereqResponse = await updateRequisitionById(requisitionId, payload)
                setAtvBtn(false)
                NotificationManager.success("Requisition updated succsesfully", "Success", 2000);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (error) {
                console.log(error, "error")
                setAtvBtn(false)
            }
        }
    }

    const cloneRequistion_data = {
        reqreferenceId: requisitionDetailsData.reqreferenceId,
        primarySkill: requisitionDetailsData.primarySkill,
        placementType: requisitionDetailsData.placementType,
        clientJobId: requisitionDetailsData.clientJobId,
        clientName: requisitionDetailsData.clientName,
        endClientName: requisitionDetailsData.endClientName,
        division: requisitionDetailsData.division,
        priority: requisitionDetailsData.priority,
        location: requisitionDetailsData.location,
        minimumBillRate: requisitionDetailsData.minimumBillRate,
        maxiMumBillRate: requisitionDetailsData.maxiMumBillRate,
        currency: requisitionDetailsData.currency,
        fee: requisitionDetailsData.fee,
        startDate: requisitionDetailsData.startDate,
        endDate: requisitionDetailsData.endDate,
        openings: requisitionDetailsData.openings,
        minimumExperienceLevel: requisitionDetailsData.minimumExperienceLevel,
        maximumExperienceLevel: requisitionDetailsData.maximumExperienceLevel,
        status: requisitionDetailsData.status,
        filledBy: requisitionDetailsData.filledBy,
        assignToValue: requisitionDetailsData.assignedTo,
        jobDescription: requisitionDetailsData.jobDescription,
        documentLink: requisitionDetailsData.documentLink,
        poc: requisitionDetailsData.poc
    }

    const cloneRequisitionHandler = async () => {
        history.push({
            pathname: "/create-requisition",
            state: { requisitionData: cloneRequistion_data, divisionName: divisionName, isClone: true }
        });
    }

    const locationTextFieldChangeHandler = (e) => {
        setLocationLoading(true)
        setLocationList([])
        setLocationSearchText(e.target.value)
    }
    const locationChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, location: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, location: [] });
        }
        setLocationList([])
        setLocationSearchText("")
    }

    const getLocationResult = async (searchText) => {
        try {
            let response = await getLocations(searchText)
            response = response.data.data
            let locationArr = []
            response.forEach((item) => {
                let str = ""
                str += (item.name).trim()
                if (item.state) {
                    str += ", " + (item?.state?.name).trim()
                }
                if (item.country) {
                    str += ", " + (item?.country?.name).trim()
                }
                locationArr.push(str)
            })
            setLocationList(locationArr)
            setTimeout(() => {
                setLocationLoading(false);
            }, 1000);
        } catch (error) {
            console.log(error)
        }
    }

    const openRecruiterRequisitionModal = () => {
        recruiterRequisitionRef.current.addRecruiterAssigment() //reference for Child component
        setRecruiterModalIsOpen(true)
    }
    const closeRecruiterRequisitionModal = (handlerAction) => {
        recruiterRequisitionRef.current.clearRecruiterRequistionData() //Trigerring the child component function
        setRecruiterModalIsOpen(false)
        if (handlerAction?.action !== "save") {
            if (userType === "MIS Operator" || userType === "Organization Admin" || userType === "Recruitment Leader") {
                teamLeaderRequisitionRef.current.handleRecruiterAssignment() //Trigerring the child component function
            }
        }
        else {
            teamLeaderRequisitionRef.current.clearTeamLeaderAssignmentData() //Trigerring the child component function
            setSelectedTeam({})
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (locationSearchText.length > 0) {
                setLocationLoading(true)
                getLocationResult(locationSearchText)
            }
            else {
                setLocationLoading(false)
                setLocationList([])
            }
        }, 700);

        return () => clearTimeout(timeout);
    }, [locationSearchText])

    return (
        <div className='reqDetailsContainer'>
            <div className='titlesofCommunity'>Requisition Details</div>
            <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashBoard(e)}>
                <ArrowBackIcon />  &nbsp;{userType === "MIS Operator" ? "Go Back to Requisition Dashboard" : "Go Back to Dashboard"}
            </div>
            <div className='reqDetailsGrid'>
                {isDataLoading ? <div className='loader_requisitionDetails'>
                    <CircularProgress />
                </div> :
                    <div className='reqDetailsLeftFormContainer'>
                        <div className='reqDetailsLeftCardGrid'>
                            {
                                requisitionDetailsData?.reqreferenceId ?
                                    <div className='reqDetailsBasicInfo'>
                                        <div>
                                            <div className='reqDetails_headings'>Reference ID</div>
                                            <div className="candidateDetails">{requisitionDetailsData?.reqreferenceId ?
                                                getWrappedReferenceId(requisitionDetailsData.reqreferenceId)
                                                : ""}</div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Primary Skill*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Primary Skills"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.primarySkill}
                                        onChange={primarySkillHandler}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Placement Type*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={placementTypeOption}
                                    disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled || (requisitionResponseData?.teamLeaderRequisitionDetails||[])?.length > 0 : true}
                                    getOptionLabel={(option) => option.PositionType || ""}
                                    value={requisitionDetailsData?.placementType || ''}
                                    onChange={placementTypeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Placement Type"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Client JobId</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Client JobId"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.clientJobId}
                                        onChange={clientJobIdHandler}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Client Name*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={clientNameOption}
                                    disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled || (requisitionResponseData?.teamLeaderRequisitionDetails||[])?.length > 0 : true}
                                    getOptionLabel={(option) => option.clientName || ""}
                                    value={requisitionDetailsData?.clientName || ''}
                                    onChange={clientNameChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Client Name"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>End Client Name</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="End Client Name"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.endClientName}
                                        onChange={endClientNameHandler}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>POC</div>
                                <Autocomplete
                                    key={requisitionDetailsData?.poc + requisitionDetailsData}
                                    id="multiple-limit-tags"
                                    options={pocName}
                                    className="createRequisition_textbox"
                                    getOptionLabel={(option) =>
                                        option.spocName ? option.spocName : ""
                                    }
                                    value={requisitionDetailsData?.poc}
                                    onChange={pocChangeHandler}
                                    filterSelectedOptions
                                    disabled={userType === "MIS Operator" ? false : true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={"Select Spoc"}
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Division*</div>

                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={divisionName}
                                    getOptionLabel={(option) =>
                                        option.divisionName ? option.divisionName : ""
                                    }
                                    disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled || (requisitionResponseData?.teamLeaderRequisitionDetails||[])?.length > 0: true}
                                    value={requisitionDetailsData?.division || ''}
                                    freeSolo={requisitionDetailsData.isDisabled}
                                    onChange={divisionChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Division"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Priority*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={priority}
                                    getOptionLabel={(option) => option.name || ""}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    value={requisitionDetailsData?.priority || ''}
                                    onChange={priorityChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Priority"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Minimum Bill Rate</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Bill Rate"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        type='number'
                                        value={requisitionDetailsData?.minimumBillRate}
                                        onChange={minimumbillRateChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Maximum Bill Rate</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Bill Rate"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        value={requisitionDetailsData?.maxiMumBillRate}
                                        onChange={MaximumbillRateChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Currency Unit</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    disabled={userType === "MIS Operator" ? false : true}
                                    options={currencyBillRate}
                                    getOptionLabel={(option) =>
                                        option.value ? option.value : ""
                                    }
                                    value={requisitionDetailsData?.currency || ''}
                                    onChange={currencyBillRateChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Currency Unit"
                                        />
                                    }
                                />
                            </div>
                            {requisitionDetailsData.placementType.PositionType !== "Contract" ?
                                <>
                                    <div className='reqDetailsBasicInfo'>
                                        <div className='reqDetails_headings'>Fee(%)</div>
                                        <FormControl fullWidth variant="outlined">
                                            <OutlinedInput
                                                placeholder="Fee"
                                                className="createRequisition_textbox_outline"
                                                type='number'
                                                disabled={userType === "MIS Operator" ? false : true}
                                                value={requisitionDetailsData?.fee}
                                                onChange={feeChangeHandler}
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </div>
                                </> : ""}


                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Opening*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Openings"
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.openings}
                                        onChange={openingChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>

                            <div className='reqDetailsBasicInfo_location'>
                                <div className='reqDetails_headings'>Location*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className={requisitionDetailsData?.placementType == "Contract" ? "createRequisition_textbox_outline" : "createRequisition_textbox"}
                                    multiple={true}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    loading={locationLoading}
                                    loadingText={<CircularProgress />}
                                    options={locationList}
                                    getOptionLabel={(option) =>
                                        option ? option : ""
                                    }
                                    filterSelectedOptions
                                    autoHighlight
                                    value={requisitionDetailsData?.location || []}
                                    onChange={locationChangeHandler}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            value={locationSearchText}
                                            onChange={(e) => locationTextFieldChangeHandler(e)}
                                            required={true}
                                            placeholder="Search locations"
                                        />
                                    }
                                />
                            </div>


                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Start Date</div>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker"
                                    selected={requisitionDetailsData?.startDate}
                                    onChange={startDateChangeHandler}
                                    dateFormat="MM/dd/yyyy"
                                    disabled={userType === "MIS Operator" ? false : true}
                                    showIcon
                                    minDate={new Date()}
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>End Date</div>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker"
                                    selected={requisitionDetailsData?.endDate}
                                    onChange={endDateChangeHandler}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    dateFormat="MM/dd/yyyy"
                                    showIcon
                                    minDate={new Date()}
                                />
                            </div>

                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Minimum Experience*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Experience"
                                        className={"createRequisition_textbox_outline"}
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.minimumExperienceLevel}
                                        onChange={minimumExperienceHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Maximum Experience*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Maximum Experience"
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.maximumExperienceLevel}
                                        onChange={maximumExperienceHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>

                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Status*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={status}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    disabled={userType === "Recruiter" || userType === "Team Leader" || userType === 'Admin' ? true : false}
                                    value={requisitionDetailsData.status || ""}
                                    onChange={statusChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Status"
                                        />
                                    }
                                />
                            </div>
                            {requisitionDetailsData?.status?.name === 'FILLED' ?
                                <div className='reqDetailsBasicInfo'>
                                    <div className='reqDetails_headings'>Filled By</div>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            placeholder="Filled By"
                                            className="createRequisition_textbox_outline"
                                            value={requisitionDetailsData?.filledBy}
                                            type='text'
                                            onChange={filledByChangeHandler}
                                        />
                                    </FormControl>
                                </div> : ""
                            }
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Assigned To*</div>
                                <FormControl fullWidth variant="outlined">
                                    {requisitionDetailsData?.assignedTo ?
                                        <Tooltip

                                            title={< React.Fragment >
                                                <h3 >{`${requisitionDetailsData?.assignedTo?.email || ""}`}</h3>
                                            </React.Fragment>}>
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className="createRequisition_textbox"
                                                options={getFilteredRecruiterLeaderOptions()}
                                                getOptionLabel={(option) =>
                                                    option.email ? option.email : ""
                                                }
                                                filterSelectedOptions
                                                autoHighlight
                                                freeSolo={requisitionDetailsData.isDisabled}
                                                disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled ||(requisitionResponseData?.teamLeaderRequisitionDetails||[])?.length > 0: true}
                                                value={requisitionDetailsData?.assignedTo || ""}
                                                onChange={assignToChangeHandler}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Assign To"
                                                    />
                                                }
                                            />
                                        </Tooltip> :
                                        <Autocomplete
                                            id="multiple-limit-tags"
                                            className="createRequisition_textbox"
                                            options={getFilteredRecruiterLeaderOptions()}
                                            getOptionLabel={(option) =>
                                                option.email ? option.email : ""
                                            }
                                            filterSelectedOptions
                                            autoHighlight
                                            freeSolo={requisitionDetailsData.isDisabled}
                                            disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled : true}
                                            value={requisitionDetailsData?.assignedTo || ""}
                                            onChange={assignToChangeHandler}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Assign To"
                                                />
                                            }
                                        />}

                                </FormControl>
                            </div>

                        </div>
                        <div className='reqDetailsBasicInfo_jobDesCription textAreaJD'>
                            <div className='reqDetails_headings'>Job Description</div>
                            <TextareaAutosize
                                minRows={7}
                                maxRows={7}
                                aria-label="maximum height"
                                placeholder="Job Description"
                                className={classes.customTextArea}
                                disabled={userType === "MIS Operator" ? false : true}
                                value={requisitionDetailsData?.jobDescription || ''}
                                onChange={jobDescriptionChangeHandler}
                            />
                        </div>
                        <div className='reqDetailsBasicInfo_ActionBtnsContainer'>
                            <div className='buttonContainer_viewRequisition_outside'>
                                {
                                    userType === "Recruiter" ? "" :
                                        <>
                                            {userType === "Admin" ? "" :

                                                <button variant="contained" className={atvBtn ? "saveButton_viewRequisition_disable" : "saveButton_viewRequisition"} onClick={(e) => saveHandler()}
                                                    disabled={atvBtn}

                                                >
                                                    <span>Save</span>
                                                    {atvBtn ? (<CircularProgress />) : null}
                                                </button>
                                            }
                                            <Button variant='outlined' className="cancelButton_viewRequisition" onClick={(e) => goToDashBoard(e)}>
                                                Cancel
                                            </Button>
                                        </>
                                }
                                {
                                    userType === "MIS Operator" ?
                                        <Button variant='contained' className="cloneRequisitionButton_viewRequisition" onClick={(e) => cloneConfirmRequisitionHandler(e)}>
                                            Clone Requisition
                                        </Button> : ""
                                }
                            </div>


                            {requisitionDetailsData.documentLink ?
                                <div className='viewButton_container_viewRequisition'>
                                    <Button variant="contained" className="viewDocumentButton_viewRequisition" onClick={(e) => openResume(requisitionDetailsData.documentLink)}>View Document</Button>
                                </div> : ""
                            }
                        </div>
                    </div>
                }

                {/* Right Side Activity Card */}
                <div className='reqDetailsRightCard'>
                    <RecentActivities requisitionId={requisitionId} idContextTest={idContextTest} userType={userType} userRecords={userRecords} userRole={userRole} userRecruitmentpartnerId={userRecruitmentpartnerId}
                        activCount={activCount} setactivCount={setactivCount}
                        page="req" />

                    <div className='recentActivities_BtnGroup'>
                        {(requisitionResponseData?.statusDetails?.name === "OPEN" || requisitionResponseData?.statusDetails?.name === "FILLED") && <>
                            {isDataLoading ? null :
                                userType === "Recruitment Leader" || userType === "MIS Operator" || userType === "Organization Admin" ?
                                    <Button variant="contained" className='button_AssignRecruiter' onClick={(e) => openModal()}>
                                        <div className='maindivButton_requisitionBody'>
                                            {((requisitionResponseData?.teamLeaderRequisitionDetails?.length > 0)) ? <VisibilityIcon className='iconDivision_viewRequisition' /> : <PersonAdd className='iconDivision_viewRequisition' />}
                                            {(requisitionResponseData?.teamLeaderRequisitionDetails?.length > 0) ? <span>View Assigned Team</span> : <span>Assign Team </span>}
                                        </div>
                                    </Button> :
                                    (userType === "Team Leader" || userType === "Recruiter") ?
                                        <Button variant="contained" className='button_AssignRecruiter' onClick={(e) => openRecruiterRequisitionModal()}>
                                            <div className='maindivButton_requisitionBody'>
                                                {((requisitionResponseData?.recruiterRequisitionDetails?.length > 0)) ? <VisibilityIcon className='iconDivision_viewRequisition' /> : <PersonAdd className='iconDivision_viewRequisition' />}
                                                {(requisitionResponseData?.recruiterRequisitionDetails?.length > 0) ? (userType === "Recruiter") ? <span>View Assignment</span> : <span>View Assigned Recruiter</span> : <span>Assign Recruiter</span>}
                                            </div>
                                        </Button>
                                        : null
                            }

                            {userType === "Recruitment Leader" || userType === "Recruiter" || userType === "Team Leader" || userType === "MIS Operator" || userType === "Organization Admin" ?
                                <Button variant="contained" className='button_candidateSearch' onClick={(e) => goToSearchCandidate()}>
                                    <div className='maindivButton_requisitionBody'>
                                        <Search className='iconDivision_viewRequisition' />
                                        <span>Candidate Search</span>
                                    </div>
                                </Button>
                                : null}
                            {userType === "Recruitment Leader" || userType === "Recruiter" || userType === "Team Leader" || userType === "MIS Operator" || userType === "Organization Admin" ?
                                <Button variant="contained" className='button_uploadResume' onClick={(e) => handleUploadResumes()}>
                                    <div className='maindivButton_requisitionBody'>
                                        <CloudUpload className='iconDivision_viewRequisition' />
                                        <span>Upload Resume</span>
                                    </div>
                                </Button>
                                : null}
                        </>}
                    </div>
                </div>

            </div >

            <TeamLeaderRequistionAssignment
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                divisionByOrgData={divisionByOrgData}
                requisitionResponseData={requisitionResponseData}
                status={status}
                requisitionId={requisitionId}
                ref={teamLeaderRequisitionRef} // reference for Child component
                getRequisitionDetail={getRequisitionDetail}
                setSelectedTeam={setSelectedTeam}
                selectedTeam={selectedTeam}
                openRecruiterRequisitionModal={openRecruiterRequisitionModal}
                setmodalIsOpen={setmodalIsOpen}
                recruitmentpartnerId={userRecruitmentpartnerId}
                displayName={userRecords.displayName}
                id={idContextTest}
                userType={userType}
            />

            <RecruiterRequisitionAssignment
                recruiterModalIsOpen={recruiterModalIsOpen}
                setRecruiterModalIsOpen={setRecruiterModalIsOpen}
                requisitionResponseData={requisitionResponseData}
                closeRecruiterRequisitionModal={closeRecruiterRequisitionModal}
                divisionByOrgData={divisionByOrgData}
                ref={recruiterRequisitionRef} // reference for Child component
                status={status}
                getRequisitionDetail={getRequisitionDetail}
                selectedTeam={selectedTeam}
            />

            {/* Clone Requisition Modal */}
            <Dialog
                onClose={closeCloneRequisitionModal}
                open={cloneRequisitionModal}
                aria-labelledby="customized-dialog-title"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogContent >
                    <div className="fs-semi-bold f-color fs-18">Do you want to clone this requisition?</div>
                </DialogContent>
                <DialogActions>
                    <div className='button_Container_CreateRequisition cloneRequisitionBtnContainer'>
                        <Button variant="contained" color="primary" className="cloneRequisition_createRequisition_dialogBtn" onClick={cloneRequisitionHandler}
                        >
                            Confirm
                        </Button>
                        <Button variant="contained" color="secondary" className='ButtonCancel_cloneRequisition' onClick={closeCloneRequisitionModal}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            {/* Resume modal */}

            <UploadResumeModal
                showResumeModal={showResumeModal}
                setShowResumeModal={setShowResumeModal}
                id={idContextTest}
                userRecruitmentpartnerId={userRecruitmentpartnerId}
            />


            <NotificationContainer />
        </div >
    )
}

export default RequisitionDetailsBody